$primary: #FF6F2D;

$theme-colors: (
  "primary": $primary,
);

@import url('https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap');

@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5 {
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
}

@import "~bootstrap-slider/dist/css/bootstrap-slider.css";

.slider-handle {
  background: $primary;
}

.excluded {
  opacity: 0.25;

  @media print {
    display: none;
  }
}

.card {
  margin-bottom: 1em;
  break-inside: avoid;
}

span {
  &.triangle { color: black;}
  &.circle   { color: white; text-shadow: 0 0 2px black; }
  &.square   { color: blue;}
  &.pentagon { color: red;}
}

.jumbotron {
  background: linear-gradient(90deg, lightgray 50%, transparent), black url('images/background.jpeg') right/contain no-repeat;
}

.WH40k9e {

  .phase-morale .units, .phase-movement .units {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

    .card {
      border: 0;
    }

  }

  @media print {
    .card-columns {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
  }
}
